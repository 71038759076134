import React, { useMemo } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import OrderItemList from './OrderItemList'

const OrderItemInfo = ({ order, showStock = true }) => {
    const orderItemList = useMemo(() => {
        const resultMap = new Map()
        const stockMap = new Map()

        const processFinishedEnds = (finishedEnds) => {
            finishedEnds.forEach(({ item, quantity }) => {
                resultMap.set(
                    item?.itemSKU,
                    (resultMap.get(item?.itemSKU) || 0) + quantity
                )
                stockMap.set(item?.itemSKU, {
                    stock: item?.stock,
                    location: item?.warehousemaps,
                })
            })
        }

        const processProductItems = (orderItems) => {
            orderItems.forEach((item) => {
                const itemQuantity = item.quantity

                if (item.isItem) {
                    resultMap.set(
                        item?.item?.itemSKU,
                        (resultMap.get(item?.item?.itemSKU) || 0) + itemQuantity
                    )
                    stockMap.set(item?.item?.itemSKU, {
                        stock: item?.item?.stock,
                        location: item?.item?.warehousemaps,
                    })
                }
                if (
                    item?.productcolor?.productitems?.length > 0 &&
                    !item.isItem
                ) {
                    item.productcolor.productitems.forEach((productItem) => {
                        const { item, quantity: productItemQuantity } =
                            productItem
                        const quantity = itemQuantity * productItemQuantity
                        resultMap.set(
                            item?.itemSKU,
                            (resultMap.get(item?.itemSKU) || 0) + quantity
                        )
                        stockMap.set(item?.itemSKU, {
                            stock: item?.stock,
                            location: item?.warehousemaps,
                        })
                    })
                }
            })
        }

        if (order?.orderitemfinishedends?.length > 0) {
            processFinishedEnds(order.orderitemfinishedends)
        }

        if (order?.orderstyles?.length > 0) {
            order.orderstyles.forEach((style) => {
                if (style?.orderitems?.length > 0) {
                    processProductItems(style.orderitems)
                }
            })
        }

        const result = Array.from(resultMap, ([itemSKU, quantity]) => ({
            itemSKU,
            quantity,
            stock: stockMap.get(itemSKU)?.stock || 0,
            location: stockMap.get(itemSKU)?.location,
        }))
        return result
    }, [order])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Quantity</TableCell>
                        {showStock && (
                            <>
                                <TableCell>Stock</TableCell>
                                <TableCell>Location</TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderItemList?.map((item) => (
                        <OrderItemList
                            item={item}
                            key={item.itemSKU}
                            showStock={showStock}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderItemInfo
