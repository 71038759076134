import { Container } from '@mui/material'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../form/Header'
import AppForm from '../form/AppForm'
import {
    addNewDefectiveItem,
    getSearchItems,
} from '../../store/actions/defectiveItemAction'
import ItemSelect from '../form/ItemSelect'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import AppSelectNameId from '../form/AppSelectNameId'
import { AppSelectId } from '../form/AppSelect'

const defectiveTypeList = [
    { id: 2, name: 'Decrease' },
    { id: 1, name: 'Increase' },
]

const DefectiveItemForm = ({ setOpen }) => {
    const dispatch = useDispatch()
    const { searchItems, suppliers } = useSelector(
        (state) => state.defectiveItem
    )

    const [itemId, setItemId] = useState(null)
    const [itemStock, setItemStock] = useState(0)
    const [itemOption, setItemOption] = useState(null)

    const validationSchema = Yup.object().shape({
        quantity: Yup.number()
            .required()
            .label('Quantity')
            .test(
                'must less stock',
                'quantity over stock',
                (value) => value <= itemStock
            ),
        supplierId: Yup.string().required().label('Supplier'),
    })

    const handleSearchItemChange = (option) => {
        if (option) {
            const [, stock] = option.label.split('|')
            setItemId(option.value)
            setItemStock(stock)
            setItemOption(option)
        }
    }

    const handleInputChange = (inputValue) => {
        const values = { search: inputValue }
        dispatch(getSearchItems(values))
    }

    return (
        <Container style={{ height: '90vh', overflow: 'scroll' }}>
            <Header title="Add Defective Item" />
            <AppForm
                initialValues={{
                    quantity: '',
                    defectiveType: 2,
                    supplierId: suppliers?.[0]?.id,
                }}
                onSubmit={(values) => {
                    const submitValues = { ...values, itemId }
                    dispatch(addNewDefectiveItem(submitValues))
                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppSelectNameId
                    name="supplierId"
                    label="Supplier"
                    options={suppliers}
                />
                <ItemSelect
                    name="itemOption"
                    value={itemOption}
                    onChange={handleSearchItemChange}
                    onInputChange={handleInputChange}
                    options={searchItems}
                    placeholder="Defective Item"
                />
                {itemId && (
                    <>
                        <AppSelectId
                            name="defectiveType"
                            label="Type"
                            options={defectiveTypeList}
                        />
                        <AppInput
                            name="quantity"
                            label={`Defective Quantity`}
                            type="number"
                            required={true}
                            inputProps={{ maxLength: 10 }}
                        />
                    </>
                )}
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default DefectiveItemForm
