// const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth'
const logoList = {
    woodworth: {
        LOGO: 'https://res.cloudinary.com/home-space/image/upload/v1705806046/Logo/ww_logo_200x180-b8526767_qxich6.webp',
        PDF: 'https://res.cloudinary.com/home-space/image/upload/v1705806046/Logo/ww_logo_200x180-b8526767_qxich6.png',
    },
    dw: {
        LOGO: 'https://res.cloudinary.com/homespace/image/upload/v1717423280/logo/wood_np7djh.webp',
        PDF: 'https://res.cloudinary.com/homespace/image/upload/v1717423280/logo/wood_np7djh.png',
    },
}

export default logoList
